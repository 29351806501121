import React, { FC } from 'react';
import styled from 'styled-components';

import { mobile, smMobile, tablet, useQuery } from 'styles/breakpoints';
import PaymentSteps from 'pages/start/payments/components/PaymentSteps';
import Headline from '../Headline';
import Label from '../Label';
import Button from '../Button';
import ImageSection from 'pages/start/checkout/components/ImageSection';
import BannerOne from '../BannerOne';
import CancelButton from '../CancelButton';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { updateLead, updateQuizAnswers } from 'state/user/effects';
import { getLocalisedProduct } from 'utils/localization';
import { Loader } from 'components';

interface HardcoverProps {}

const PaymentStepsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  width: 100%;
  background-color: #f6f4ef;
  @media ${mobile} {
    padding: 1.5rem 2.125rem;
  }
  @media ${smMobile} {
    padding: 1.5rem 0.75rem;
  }
`;

const PaymentStepsStyled = styled(PaymentSteps)`
  max-width: 56.25rem;
  width: 100%;
`;

const LabelStyled = styled(Label)`
  margin-top: 0.75rem;
  @media ${tablet} {
    margin-top: 1rem;
  }
`;

const ImageContainer = styled.div<{ bgColor: string; padding: string }>`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor};
  padding: ${({ padding }) => padding};
`;

const ImageSectionStyled = styled(ImageSection)<{ width: string }>`
  width: 100%;
  max-width: 21.53125rem;
  margin-bottom: 2.5rem;
  @media ${tablet} {
    max-width: 16.34375rem;
    margin-bottom: 1.5rem;
  }
`;

const ImageSectionStyledBottom = styled(ImageSectionStyled)`
  width: 100%;
  max-width: 21.53125rem;
  margin-bottom: 0;
  @media ${tablet} {
    max-width: 16.34375rem;
  }
`;

const HeadlineBiggerContainer = styled.div`
  background-color: #f6f4ef;
  width: 100%;
  padding-bottom: 2.25rem;
  padding: 1rem;
  @media ${tablet} {
    padding-bottom: 1rem;
  }
`;

const HeadlineBigger = styled.p`
  color: #1c1c28;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  @media ${tablet} {
    font-size: 1.25rem;
  }
`;

const HeadlineSmaller = styled.p`
  color: #363648;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.028rem;
  margin-top: 7rem;
  @media ${tablet} {
    margin-top: 4rem;
    font-size: 1.25rem;
  }
`;

const CancelButtonStyled = styled(CancelButton)`
  margin-bottom: 3rem;
`;

const UpgradeBtnContainer = styled.div`
  margin-bottom: 0.75rem;
`;

const Hardcover: FC<HardcoverProps> = ({
  bannerOne,
  paymentSteps,
  headline,
  planSection,
  PlanSectionComponent,
  headline2,
  headline3,
  label,
  imageSection2,
  imageSection,
  button,
  loading,
  handleUpgradeClick,
  localisedProduct,
  userHasBoughtPaperbackBook,
  handleCancelClick,
  cancelButton,
  bannerTexts,
}) => {
  const { isTablet } = useQuery();
  const { quiz_answers, code } = useSelector((state: AppState) => state.user);
  const dispatch = useDispatch();
  if (!localisedProduct) {
    return null;
  }
  const localisedProductMain = getLocalisedProduct(localisedProduct);

  const upgradeHandler = () => {
    handleUpgradeClick?.();
  };
  return (
    <>
      {!bannerTexts && (
        <BannerOne
          {...bannerOne}
          boughtPaperback={userHasBoughtPaperbackBook}
        />
      )}
      <PaymentStepsContainer>
        <PaymentStepsStyled
          {...paymentSteps}
          hasOptionalStep={userHasBoughtPaperbackBook}
        />
      </PaymentStepsContainer>
      <HeadlineBiggerContainer>
        <HeadlineBigger>{headline}</HeadlineBigger>
      </HeadlineBiggerContainer>
      <ImageContainer
        bgColor={imageSection.bgHex}
        padding={imageSection.containerPadding || '0'}
      >
        <ImageSectionStyled {...imageSection} />
      </ImageContainer>
      <PlanSectionComponent {...planSection} />
      <HeadlineSmaller>{headline2}</HeadlineSmaller>
      <HeadlineBigger>{headline3}</HeadlineBigger>
      <LabelStyled {...label} align={isTablet ? 'left' : 'center'} />
      <ImageContainer
        bgColor={imageSection2.bgHex}
        padding={imageSection2.containerPadding || '0'}
      >
        <ImageSectionStyledBottom {...imageSection2} />
      </ImageContainer>
      {loading ? (
        <UpgradeBtnContainer>
          <Loader wrapperHeight={'10'} />
        </UpgradeBtnContainer>
      ) : (
        <Button
          bgColor="secondary"
          disabled={loading}
          onClick={upgradeHandler}
          {...button}
          secondaryText={`${button.secondaryText} ${localisedProduct?.currency}${localisedProduct?.finalPrice}`}
        >
          {button.text}
        </Button>
      )}
      <CancelButtonStyled
        disabled={loading}
        onCancelClick={handleCancelClick}
        noButtonTitle={cancelButton.noButtonTitle}
      />
    </>
  );
};

export default Hardcover;
